<template>
    <div class="enter-code">
        <StackRouterHeaderBar :leftButton="'close'" :showTitle="true" title="초대코드 입력" />
        <div class="container">
            <div class="desc">
                <p>초대 코드를 입력하고 포인트를 받아보세요.</p>
            </div>
            <div class="input-container">
                <TextareaWithX v-model="invitationCode" placeholder="초대코드를 입력해주세요." :isInputMode="true" />
                <div class="error" v-if="error" v-html="error" />
            </div>
            <div class="buttons">
                <BottomButton @click="onClickEnroll" label="입력완료" :nonFixed="true" />
                <!-- <div v-if="firstEnter">
                    <BottomButton
                        class="m-t-8"
                        :overlay="true"
                        @click="onClickNextTime"
                        label="나중에 입력하기"
                        :nonFixed="true"
                    />
                    <p class="next-time">
                        *지금 초대코드가 기억나지 않는다면
                        <span class="c-primary">'마이페이지 > 초대코드 입력'</span>에서 나중에 입력하실 수 있습니다.
                    </p>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import inviteHistoryService from '@/services/invite-history'

export default {
    name: 'EnterCodePage',
    data: () => ({
        invitationCode: null,
        error: null,
        firstEnter: true,
    }),
    mounted() {
        const firstEnter = !!localStorage.getItem('enterInviteCode')
        if (!firstEnter) localStorage.setItem('enterInviteCode', true)
        else this.firstEnter = false
    },
    methods: {
        async onClickEnroll() {
            try {
                this.loading = true
                const payload = { code: this.invitationCode }
                const data = await inviteHistoryService.create(payload)
                if (data.msg === 'success') {
                    await this.$modal.basic({
                        body:
                            '초대코드 입력 완료! 첫 번째 만남을 완료한 후에 나와 친구에게 20만원씩 포인트가 지급됩니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }
                this.$store.dispatch('loadBadges')
                this.$store.dispatch('loadMe')
                this.$stackRouter.pop()
            } catch (e) {
                this.error = e.data.msg
                this.$toast.error(e.data.msg)
            } finally {
                this.loading = false
            }
        },
        onClickNextTime() {
            this.$stackRouter.pop()
        },
    },
}
</script>

<style scoped lang="scss">
.container {
    padding: 0 16px;
    text-align: center;

    .title {
        @include spoqa-f-bold;
        font-size: 20px;
        color: black;
        margin-top: 24px;
        margin-bottom: 8px;
    }
    .desc {
        @include spoqa-f-regular;
        font-size: 16px;
        line-height: 24px;
        color: black;
        // margin-bottom: 24px;
        margin: 32px 0 40px;
    }
    .error {
        text-align: left;
        color: $pink-deep;
        font-size: 12px;
        margin-top: 4px;
    }
    .input-container {
        margin-bottom: 24px;
    }
    .next-time {
        margin-top: 8px;
        text-align: left;
        font-size: 12px;
        @include spoqa-f-regular;
    }
}
</style>
